import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { catchError, map, Observable, of } from 'rxjs';
import { ShortlistResult } from '../domain/shortlist';
import { ShortlistStorageService } from './shortlist-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiShortlistStorageService implements ShortlistStorageService {
  private readonly httpClient = inject(HttpClient);
  private readonly rmaUrls = inject(RmaUrlsService);

  private readonly shorlistAgentsByCode = 'users/me/shortlist/agents/code-{code}';

  public load(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.rmaUrls.apiUrl('users/me/shortlist/agents/codes'));
  }

  public add(agentCode: string): Observable<ShortlistResult> {
    return this.httpClient.put(this.rmaUrls.apiUrl(this.shorlistAgentsByCode.replace('{code}', agentCode)), null).pipe(
      map(() => ({ agentCode, added: true, success: true })),
      catchError((e) => {
        console.error(e);
        return of({ agentCode, added: true, success: false, error: e });
      }),
    );
  }

  public addMany(agentCodes: string[]): Observable<ShortlistResult[]> {
    return this.httpClient.put<string[]>(this.rmaUrls.apiUrl('users/me/shortlist/agents'), agentCodes).pipe(
      map((codes: string[]) => codes.map((agentCode) => ({ agentCode, added: true, success: true }))),
      catchError((e) => {
        console.error(e);
        return of(agentCodes.map((agentCode) => ({ agentCode, added: true, success: false, error: e })));
      }),
    );
  }

  public remove(agentCode: string): Observable<ShortlistResult> {
    return this.httpClient.delete(this.rmaUrls.apiUrl(this.shorlistAgentsByCode.replace('{code}', agentCode))).pipe(
      catchError((e) => {
        console.error(e);
        return of({ agentCode, added: false, success: false, error: e });
      }),
      map(() => ({ agentCode, added: false, success: true })),
    );
  }
}
