<ng-container *transloco="let t; read: scope">
  <div [class.nc-container]="!fullWidthHeader()" class="flex items-center gap-4 h-full">
    <rma-site-header-logo />

    <rma-site-header-search
      [scope]="scope"
      class="opacity-100 transition-opacity"
      [class.!opacity-0]="basicHeader()"
      [class.pointer-events-none]="basicHeader()" />

    <div
      class="grow h-full flex items-center justify-end gap-2 opacity-100 transition-opacity"
      [class.!opacity-0]="basicHeader()"
      [class.pointer-events-none]="basicHeader()">
      @defer (when compareCount()) {
        <rma-site-header-compare-agent [scope]="scope" [compareCount]="compareCount()" />
      } @error {}

      @defer (when trackedCount()) {
        <rma-site-header-track-campaign [scope]="scope" [trackedCount]="trackedCount()" />
      } @error {}

      <ng-container *rmaLet="isAuthenticated$ as isAuthenticated; suspenseRef: suspense">
        @if (!isAuthenticated) {
          @if (showMarketingNav()) {
            <rma-site-header-navigation
              class="hidden md:block"
              [scope]="scope"
              displayMode="inline"
              [about]="about()"
              [agents]="agents()"
              [group]="group()" />
          }

          <rma-site-header-login class="md:ml-4" [scope]="scope" />

          @if (showMarketingNav()) {
            <rma-site-header-navigation class="md:hidden" [scope]="scope" displayMode="menu" (toggleSideNav)="toggleSideNav()" />
          }
        } @else {
          @defer (when isAuthenticated) {
            <rma-site-header-user class="md:ml-4" [scope]="scope" [userType]="userType()" />
          } @error {}
        }
      </ng-container>

      <ng-template #suspense>
        <rma-icon glyph="sync" class="animate-spin" />
      </ng-template>
    </div>
  </div>
</ng-container>
