import { inject, Injectable } from '@angular/core';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';
import { difference } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { HARD_LIMIT, ShortlistResult, SOFT_LIMIT } from '../domain/shortlist';
import { ShortlistStorageService } from './shortlist-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalShortlistStorageService implements ShortlistStorageService {
  private readonly localStorage = inject(WA_LOCAL_STORAGE);
  private readonly key = 'Users.AgentShortlist';

  public add(agentCode: string): Observable<ShortlistResult> {
    const agentCodes = this.localStorageData;
    // add only new unique agent codes to local storage
    if (agentCodes.findIndex((x) => x === agentCode) === -1) {
      const compareAgentCount = agentCodes.length;
      if (compareAgentCount >= HARD_LIMIT) {
        return of({ agentCode, added: false, success: false, limitExceeded: 'hard' });
      }

      this.setData([...agentCodes, agentCode]);

      if ((compareAgentCount + 1) % SOFT_LIMIT === 0) {
        return of({ agentCode, added: true, success: true, limitExceeded: 'soft' });
      }
    }

    return of({ agentCode, added: true, success: true });
  }

  public addMany(agentCodes: string[]): Observable<ShortlistResult[]> {
    const currentAgentCodes = this.localStorageData;
    // add only new unique agent codes to local storage
    const newAgentCodes = difference(agentCodes, currentAgentCodes);

    if (newAgentCodes.length) {
      const compareAgentCount = currentAgentCodes.length + newAgentCodes.length;

      if (compareAgentCount >= HARD_LIMIT) {
        return of(newAgentCodes.map((agentCode) => ({ agentCode, added: false, success: false, limitExceeded: 'hard' as const })));
      }

      this.setData([...currentAgentCodes, ...newAgentCodes]);

      if (compareAgentCount % SOFT_LIMIT === 0) {
        return of(newAgentCodes.map((agentCode) => ({ agentCode, added: true, success: true, limitExceeded: 'soft' as const })));
      }
    }

    return of(newAgentCodes.map((agentCode) => ({ agentCode, added: true, success: true })));
  }

  public load(): Observable<string[]> {
    return this.localStorage ? of(this.localStorageData) : of([]);
  }

  public remove(agentCode: string): Observable<ShortlistResult> {
    this.setData(this.localStorageData.filter((code) => code !== agentCode));
    return of({ agentCode, added: false, success: true });
  }

  private setData(agentCodes: string[]): void {
    this.localStorage?.setItem(this.key, JSON.stringify(agentCodes));
  }

  private get localStorageData(): string[] {
    const value = this.localStorage?.getItem(this.key);
    return value ? (JSON.parse(value) ?? []) : [];
  }
}
