import { inject, Injectable } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentUserService } from '@rma/accounts/user';
import { DeviceIdentificationService } from '@rma/generic/util/device-identification';
import { Environment } from '@rma/generic/util/environment';
import { nonNullable } from '@rma/generic/util/operators/combined';
import { ChatApiService } from '@rma/generic/util/tracking/intercom';
import { filter, take } from 'rxjs/operators';
import { HeapService } from './heap.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HeapScriptService {
  private readonly chatApiService = inject(ChatApiService);
  private readonly currentUserService = inject(CurrentUserService);
  private readonly environment = inject(Environment);
  private readonly heapService = inject(HeapService);
  private readonly deviceIdService = inject(DeviceIdentificationService, { optional: true });
  private readonly window = inject(WA_WINDOW);

  public initialiseHeap() {
    const environmentData = {
      rma_locale: this.environment.language.locale,
      rma_country_code: this.environment.countryCode,
      rma_domain: this.window.location.hostname,
    };

    this.heapService.addUserProperties(environmentData);

    this.currentUserService.currentUser$
      .pipe(
        nonNullable(),
        filter((c) => !!c?.userId),
        untilDestroyed(this),
      )
      .subscribe((user) => {
        // because we don't handle log-out, before we identify anybody, lets reset the ident
        // this makes sure we don't join two users together, but if we just re-ident as the same user anyway, things will be fine so 🧙‍♀️
        this.heapService.resetIdentity();

        if (!user.isImpersonating) {
          this.heapService.identify(user.userId);

          this.chatApiService.userChatData$.pipe(take(1)).subscribe((t: Record<string, unknown>) => {
            // https://developers.heap.io/docs/using-identify#when-and-where-to-call-identify-and-adduserproperties
            const x = { ...t };
            // jam chat data into heap, WITHOUT:
            delete x.user_id;
            delete x.user_hash;
            delete x.app_id;
            delete x.rma_locale;
            // If you ever get `TypeError: window.heap.push is not a function` ---> https://github.com/segmentio/analytics.js/issues/605
            this.heapService.addUserProperties(x);

            if (this.deviceIdService) {
              this.heapService.addEventProperties({ rma_device_id: this.deviceIdService.getDeviceId() });
            }
          });
        }

        this.heapService.addUserProperties({ 'rma.authenticatedViaRadmin': user.isImpersonating });
      });
  }
}
