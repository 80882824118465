import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'promoted',
    children: [
      {
        path: 'listing',
        loadChildren: () => import('@rma/promoter/listing-landing-page'),
      },
      {
        path: 'award',
        loadChildren: () => import('@rma/promoter/awards-landing-page'),
      },
      {
        path: 'review',
        loadChildren: () => import('@rma/promoter/review-landing-page'),
      },
      {
        path: 'searchad',
        loadChildren: () => import('@rma/promoter/search-landing-page'),
      },
      {
        path: 'appraisal',
        loadChildren: () => import('@rma/promoter/appraisal-landing-page'),
      },
    ],
  },
  {
    path: 'promoter/one-click-start-promotion',
    loadChildren: () => import('@rma/promoter/thank-you-landing-page'),
  },
  // Error Pages
  {
    path: '',
    data: {
      hideBackButton: true,
    },
    loadChildren: () => import('@rma/generic/fallback-pages'),
  },
];
